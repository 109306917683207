import React from 'react';

import './postTitleContainer.css';

export const PostTitleContainer = ({ data }) => {
    const { title, date, slogan, linkTitle, link } = data;
    return (
        <div className="post-title-container">
            <div className="post-title post-title-text">
                {' '}
                {title}{' '}
            </div>
            <span className="post-date">
                {' '}
                {date} {slogan && '-'} <em>{slogan}</em> -{' '}
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer">
                    {linkTitle}
                </a>{' '}
            </span>
        </div>
    );
};
