import React from 'react';
import { Section1 } from './Section1';
import { Divider } from '../Divider';
import { Section2 } from './Section2';
import { Container } from 'react-bootstrap';

import { Section4 } from './Section4';
import { Section5 } from './Section5';
import { Section6 } from './Section6';
import { Section7 } from './Section7';
import { Section8 } from './Section8';
import { Section9 } from './Section9';
import { Section10 } from './Section10';
import { ImageList } from '../ImageList';
import makeMachineseam from '../../assets/img/make-machineseam.png';
import makeSewingjess from '../../assets/img/make-sewingjess.png';
import makeKayaSews from '../../assets/img/make-kaya_sews.png';
import makeJackieeeSews from '../../assets/img/make-jackieee.sews.png';
import makeSouthernMims from '../../assets/img/make-southern_mims.png';
import makeSewnbyashika from '../../assets/img/make-sewnbyashika.png';
import finalBelt from '../../assets/img/final-belt.jpg';
import finalMovement from '../../assets/img/final-movement.jpg';
import finalBack from '../../assets/img/final-back.jpg';
import finalEmbroidery from '../../assets/img/final-embroidery.jpg';
import { ImageListFor4Image } from '../ImageListFor4Image';
import { Research } from '../Research';

const contenArr = [
    {
        img: makeMachineseam,
        title: '@machineseam',
        link: 'https://www.instagram.com/p/CTP9pH3suGQ/',
    },
    {
        img: makeSewingjess,
        title: '@sewingjess',
        link: 'https://www.instagram.com/p/COOGx-irmG5/',
    },
    {
        img: makeKayaSews,
        title: '@kaya_sews',
        link: 'https://www.instagram.com/p/Ch0ennpPjnX/',
    },
    {
        img: makeJackieeeSews,
        title: '@jackieee.sews',
        link: 'https://www.instagram.com/p/CQPzqkhBu-K/',
    },
    {
        img: makeSouthernMims,
        title: '@southern_mims',
        link: 'https://www.instagram.com/p/CePOJLGvEcq/',
    },
    {
        img: makeSewnbyashika,
        title: '@sewnbyashika',
        link: 'https://www.instagram.com/p/Cbw6ysbqQed/',
    },
];

const titleLink = {
    link: 'https://www.instagram.com/explore/tags/peppermintvalleyjumpsuit/',
    title: 'Peppermint Valley Jumpsuit makes',
};

const imageListFinal = [
    finalBelt,
    finalMovement,
    finalBack,
    finalEmbroidery,
];

const descrReserch =
    "I searched through <a href='https://www.instagram.com/explore/tags/peppermintvalleyjumpsuit/'> #peppermintvalleyjumpsuit </a>on Instagram to find some notes I could learn from. As with any jumpsuit, it's hard to get the proportions of your torso vs. lower half right! I'd really recommend toiling this one. Here's what I learned:";
const listResearch = [
    {
        title: " It's <b>hard to raise your arms</b>",
        content: [
            '<a href="https://www.instagram.com/p/CPV5YDjMbdn/" target="_blank" rel="noopener noreferrer"> @makingfeelsgood</a> - <em>" I am not a huge fan of the one piece bodice, I find it uncomfortable to raise my arms and the bulky waistband is rising under my armpits every time I try to reach over my head. I still think that this pattern has a good potential, but it’s just not for me."</em>',
            '<a href="https://www.instagram.com/p/CU3t7c9PJ0N/" target="_blank" rel="noopener noreferrer">@la_zoista</a> - <em>"Unfortunately grown on sleeves also meant I couldn’t raise my arms higher than my shoulders (doh!) so inserted sleeve gussets following a tutorial from @threadsmagazine"</em>',
            '<a href="https://www.instagram.com/p/CUwZ2IYljD3/" target="_blank" rel="noopener noreferrer">@magpieandthyme</a> - <em>" I made a few mods to the pattern second time around and changed the boat neckline to more of a crew neck style and lengthened the bodice by about 5cm for extra arm movement. Love this pattern though and can’t wait to highlighter my way around town in this baby!"</em>',
            '<a href="https://www.instagram.com/p/CPZWObisPpm/" target="_blank" rel="noopener noreferrer">@themakersmakes</a> - <em>"I also forgot to mention last time I posted about this jumpsuit that I ended up having to rip out the elastic waist and put in a narrower 3/4” elastic because the crotch would ride up so high when I raised my arms up even slightly. And I also made the elastic channel opposite of the pattern’s recommendation, using the top of the pants section instead of the bottom of the bodice section to fold over and make the channel."</em>',
        ],
    },
    {
        title: 'Has <b>lots of ease</b>, many people chose to size down',
        content: [
            '<a href="https://www.instagram.com/p/CPYo3ZFMpWA/" target="_blank" rel="noopener noreferrer">@slowsewed </a> - <em>"(considering the amount of ease I decided to size down, I normally sew a 34-35 with this brand)."</em>',
            `<a href="https://www.instagram.com/p/CakFjFLMqeU/" target="_blank" rel="noopener noreferrer"> @crookedpins </a> - <em> "The bodice pieces shrunk by about an inch immediately after I cut them, and everything frayed all over the place while I sewed, but because it's an oversized pattern I got away with it."</em>`,
            `<a href="https://www.instagram.com/p/CZiXaPPBNT9/" target="_blank" rel="noopener noreferrer"> @simoneandginko </a> - <em>"It fits really well and has a good amount of ease. It is the first jumpsuit I’ve sewn and there will definitely be more!" </em>`,
        ],
    },
    {
        title: `Some people needed the keyhole opening, others did not, this is something to toile :)`,
        content: [],
    },
    {
        title: `Here are other blog posts about the pattern:`,
        content: [
            `Conscious by Cholé: <a href="https://consciousbychloe.com/2021/04/14/peppermint-magazine-valley-jumpsuit-pattern/" target="_blank" rel="noopener noreferrer"> "The Valley Jumpsuit" </a>`,
            `So, Zo, What do you know?: <a href="https://sozowhatdoyouknow.blogspot.com/2021/07/free-pattern-friday-adults-valley.html?m=1" target="_blank" rel="noopener noreferrer"> "Free Pattern Friday: Adult's Valley Jumpsuit" </a>`,
            `Peppermint Valley's <a href="https://www.youtube.com/playlist?list=PLQaZHdoQcIdNJgN5dJqF2wlKxeq_GhHRB" target="_blank" rel="noopener noreferrer"> Youtube Playlist </a>`,
            `Email me at <b>sewlikehoney</b> &lt;[ at ]&gt; gmail if you want your blog post added to this list :)`,
        ],
    },
];
export const ValleyMain = () => {
    return (
        <Container>
            <Section1 />
            <Divider />
            <Section2 />
            <Divider />
            <Research
                title="Research"
                textBefore={descrReserch}
                listItems={listResearch}
            />
            <Divider />
            <Section4 />
            <Divider />
            <Section5 />
            <Divider />
            <Section6 />
            <Divider />
            <Section7 />
            <Divider />
            <Section8 />
            <Divider />
            <Section9 />
            <Divider />
            <Section10 />
            <Divider />

            <ImageListFor4Image
                imageList={imageListFinal}
                title="Final photos"
            />
            <Divider />
            <ImageList
                images={contenArr}
                title="Other detailed"
                titleLink={titleLink}
            />
            <Divider />
        </Container>
    );
};
