import React from 'react';
import { Col, Row } from 'react-bootstrap';
import fabric from '../../../assets/img/fabric.png';
import fabricEbay from '../../../assets/img/fabric_ebay.png';
import fabricCloseup from '../../../assets/img/fabric_closeup.png';
import { PostTitle } from '../../PostTitle';

export const Section3 = () => {
    return (
        <>
            <PostTitle title="Fabric" />
            <Row className="m-2">
                <Col>
                    I had just enough of this weird Victorian-looking
                    cat fabric to make a cropped blazer. When I bought
                    my dress form off FB Marketplace last summer, I
                    asked the person if they had any extra fabric
                    (they were moving.) They pulled out this bundle
                    from under a bed and said to take it!
                    <br />
                    Pretty sure the fabric is supposed to be for
                    curtains -- which was confirmed when I googled the
                    inscription on the fabric,{' '}
                    <b>
                        "Killkenny Cats fabric 16259 copyright MCMXCV
                        Scalamandre"
                    </b>
                    , and curtains came up in the image search.{' '}
                    <em>
                        (I just googled what MCMXCV is in roman
                        numerals and it's 1995!!! glad we could give
                        this fabric new purpose after 27 years under a
                        bed)
                    </em>
                </Col>
            </Row>
            <Row>
                <Col>
                    <img
                        className="w-100 inspo-img"
                        src={fabric}
                        alt="TODO"
                    />
                    <span className="post-img-caption">
                        {' '}
                        Fabric came with my dress form{' '}
                    </span>
                </Col>
                <Col>
                    <img
                        className="w-100 inspo-img"
                        src={fabricEbay}
                        alt="TODO"
                    />
                    <span className="post-img-caption">
                        {' '}
                        <a
                            href="https://www.worthpoint.com/worthopedia/kilkenny-cats-toile-blue-fabric-173167506"
                            target="_blank"
                            rel="noopener noreferrer">
                            {' '}
                            found fabric online{' '}
                        </a>
                    </span>
                </Col>
                <Col>
                    <img
                        className="w-100 inspo-img"
                        src={fabricCloseup}
                        alt="TODO"
                    />
                    <span className="post-img-caption">
                        {' '}
                        a close up
                    </span>
                </Col>
            </Row>
        </>
    );
};
