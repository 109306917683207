import React from 'react';
import { Col, Row } from 'react-bootstrap';
import inspo from '../../../assets/img/inspo.png';
import { PostTitle } from '../../PostTitle';

export const Section2 = () => {
    return (
        <>
            <PostTitle title="Inspiration" />
            <Row className="m-2 justify-content-center">
                <Col>
                    <a
                        href="https://www.instagram.com/p/CYpJxbJrm5U/?igshid=YmMyMTA2M2Y="
                        target="_blank"
                        rel="noopener noreferrer">
                        This version of the Rose Cafe Bustier from
                        @princessepersil
                    </a>{' '}
                    top has been living in my head for a long time --
                    so beautiful!
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={5}>
                    <img
                        className="w-100 inspo-img"
                        src={inspo}
                        alt="Flat lay of a gold leather bustier"
                    />
                </Col>
            </Row>
        </>
    );
};
