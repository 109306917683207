import React from 'react';
import { Col, Row } from 'react-bootstrap';
import front from '../../../assets/img/front.jpg';

export const Section1 = () => {
    return (
        <Row className="justify-content-center">
            <Col xs={4}>
                <img
                    className="w-100 post-img"
                    src={front}
                    alt="flat lay of a black leather bustier layed flat on a white bedsheet"
                />
                <span className="post-img-caption">
                    📍New York, New york{' '}
                </span>
            </Col>
            <Col md="auto">
                <p className="blog-post-intro-text">
                    I worked with leather for the first time and it
                    was great! I sewed a black leather bustier on my{' '}
                    <a href="https://www.singer.com/heavy-duty-4423-sewing-machine">
                        home sewing machine{' '}
                    </a>
                    . I’m going to share some tips I found/general
                    resources for sewing the Rose Cafe Bustier and
                    working with leather.
                    <br />
                    <br />
                    I just finished up a 6-week shoemaking className
                    where I worked with leather for the first time. I
                    learned how to mark out a pattern, how to cut
                    leather, and that it can be okay for leather to
                    take a teeeeny bit of heat/steam when needed.
                    Shoemaking ≠ Garment making, but it gave me the
                    confidence to try working with leather at home!
                    <br />
                    <br />I spent a lot of time fitting the Rose Cafe
                    Bustier over the summer in preparation for sewing
                    a July wedding guest dress for my friend’s
                    wedding. The fit in the cups still isn’t perfect
                    for me, but it works! This post doesn’t focus on
                    those alterations, but hopefully a follow-up post
                    will :)
                </p>
            </Col>
        </Row>
    );
};
