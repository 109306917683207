import React from 'react';
import {
    LeatherbustierMain,
    PostEnd,
    PostTitleContainer,
} from '../../components';

const leatherbustierBlog = {
    title: 'Leather Learnings: Rose Cafe Bustier',
    date: 'Nov 30, 2022',
    slogan: "leather isn't so scary!",
    linkTitle: 'Rose Cafe Bustier Pattern',
    link: 'https://www.etsy.com/listing/928865664/updated-soft-cup-bustier-bodice-dress-eu',
};

export const Leatherbustier = () => {
    return (
        <>
            <PostTitleContainer data={leatherbustierBlog} />
            <LeatherbustierMain />
            <PostEnd />
        </>
    );
};
