import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './footer.css';
import insta from '../../assets/img/insta-logo-yellow.svg';
import rLogo from '../../assets/img/r-logo-white.svg';
import { SHOW_NAV as showNav } from '../../config';

export const Footer = () => {
    const location = useLocation();
    return (
        <>
            {!showNav.includes(location.pathname) && (
                <div className="footer">
                    <Link className="footer-link" to="about">
                        <span>About</span>
                    </Link>
                    <span>|</span>
                    <Link className="footer-link" to="/">
                        <span>All Posts</span>
                    </Link>
                    <span>|</span>
                    <Link className="footer-link" to="signup">
                        <span>Newsletter</span>
                    </Link>
                    <span>|</span>
                    <a
                        className="footer-link no-underline"
                        href="https://www.ravelry.com/people/grayseabay"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                            className="title-link"
                            src={rLogo}
                            alt="ravlery logo"
                        />
                    </a>
                    <a
                        className="footer-link no-underline"
                        href="https://www.instagram.com/sew.like.honey/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                            className="title-link"
                            src={insta}
                            alt="instagram logo"
                        />
                    </a>
                </div>
            )}
        </>
    );
};
