import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer, Header } from './components';
import {
    Home,
    About,
    Signup,
    Valley,
    Leatherbustier,
    Croppedblazer,
} from './pages';

function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="signup" element={<Signup />} />
                <Route path="valley" element={<Valley />} />
                <Route
                    path="leatherbustier"
                    element={<Leatherbustier />}
                />
                <Route
                    path="croppedblazer"
                    element={<Croppedblazer />}
                />
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
