import React from 'react';
import { Col, Row } from 'react-bootstrap';
import inspiration from '../../../assets/img/inspiration0.png';
import inspiration1 from '../../../assets/img/inspiration1 (1).png';
import inspiration2 from '../../../assets/img/inspiration2 (1).png';
import { PostTitle } from '../../PostTitle';

export const Section2 = () => {
    return (
        <>
            <PostTitle title="Inspiration" />
            <Row className="m-2">
                <Col>
                    Originally, I wanted to lengthen the Heather
                    Blazer to make a full{' '}
                    <span>
                        <a
                            href="https://www.youtube.com/watch?v=X5KmB8Laemg"
                            target="_blank"
                            rel="noopener noreferrer">
                            {' '}
                            loooonnng jacket{' '}
                        </a>
                    </span>
                    . But spring came around and I rembered Nisan's
                    cropped blazer.
                </Col>
            </Row>
            <Row>
                <Col>
                    <img
                        className="w-100 inspo-img"
                        src={inspiration}
                        alt="Nisan in her cropped blazer"
                    />
                    <span className="post-img-caption">
                        {' '}
                        <a
                            href="https://www.thesocialfabric.net/blog/cropped-blazer"
                            target="_blank"
                            rel="noopener noreferrer">
                            {' '}
                            Nisan's Grasser 759 cropped blazer{' '}
                        </a>{' '}
                    </span>
                </Col>
                <Col>
                    <img
                        className="w-100 inspo-img"
                        src={inspiration1}
                        alt="TODO"
                    />
                    <span className="post-img-caption">
                        {' '}
                        ❓ found on Pinterest{' '}
                    </span>
                </Col>
                <Col>
                    <img
                        className="w-100 inspo-img"
                        src={inspiration2}
                        alt="TODO"
                    />
                    <span className="post-img-caption">
                        {' '}
                        ❓ found on Pinterest{' '}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col>
                    Nisan's cropped blazer (
                    <a
                        href="https://www.thesocialfabric.net/blog/cropped-blazer"
                        target="_blank"
                        rel="noopener noreferrer">
                        blog post
                    </a>
                    ) (
                    <a
                        href="https://www.instagram.com/p/CGnN7iwl8cB/"
                        target="_blank"
                        rel="noopener noreferrer">
                        insta post
                    </a>
                    ) used Grasser 759 pattern, and she goes into
                    detail about the incredible tailoring adjustments
                    in her blog. I thought it might be possible to
                    create a more beginner/intermediate friendly
                    version by hacking the Heather Blazer. From
                    Nisan's{' '}
                    <a
                        href="https://www.instagram.com/p/CKEmJ3vFz4K/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Heather Blazer details
                    </a>
                    , I learned that the as-is Heather Blazer length
                    would be too long on me anyway. And this project
                    began!
                </Col>
            </Row>
        </>
    );
};
