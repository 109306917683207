import React from 'react';
import { Col, Row } from 'react-bootstrap';
import sizeChart from '../../../assets/img/size_chart.png';

import { PostTitle } from '../../PostTitle';

export const Section5 = () => {
    return (
        <>
            <PostTitle title="Choosing a size" />
            <Row>
                <Col>
                    My measurements at the time of making this were B
                    37" / W 30" / H 37". According to the{' '}
                    <a
                        href="https://fridaypatterncompany.com/products/heather-blazer-pdf-pattern"
                        target="_blank"
                        rel="noopener noreferrer">
                        Heather Blazer
                    </a>{' '}
                    size chart:
                    <ul>
                        <li>
                            M is B (36"-
                            <span className="blog-matching-measurement">
                                37"
                            </span>
                            ), W (28"-29"), H (
                            <span className="blog-matching-measurement">
                                37"
                            </span>
                            -38")
                        </li>
                        <li>
                            L is B (39"-39"), W (
                            <span className="blog-matching-measurement">
                                30"
                            </span>
                            -32"), H (39"-41")
                        </li>
                    </ul>
                    This put me somewhat in the middle, but I felt
                    confident M would be fine since my research above
                    said it tends to run larger.
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={10}>
                    <a
                        href="https://fridaypatterncompany.com/products/heather-blazer-pdf-pattern"
                        target="_blank"
                        rel="noopener noreferrer">
                        <img
                            className="w-100 post-img"
                            src={sizeChart}
                            alt="TODO"
                        />
                    </a>
                </Col>
            </Row>
        </>
    );
};
