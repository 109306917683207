import React from 'react';
import { Container } from 'react-bootstrap';
import { ImageList } from '../ImageList';

import aminaTwo from '../../assets/img/amina_two.png';
import ampersandsewing from '../../assets/img/ampersandsewing.png';
import makepractice from '../../assets/img/makepractice.png';
import nerrisapratt from '../../assets/img/nerrisapratt.png';
import slowsewed from '../../assets/img/slowsewed.png';
import whitMakes from '../../assets/img/whit_makes.png';
import crossGrain from '../../assets/img/cross_grain.png';
import lapel from '../../assets/img/lapel.png';
import movement from '../../assets/img/movement.png';
import colorBlock from '../../assets/img/color_block.png';
import { Divider } from '../Divider';
import { Section1 } from './Section1';
import { Section2 } from './Section2';
import { Section3 } from './Section3';
import { Section5 } from './Section5';
import { Section6 } from './Section6';
import { Section7 } from './Section7';
import { ImageListFor4Image } from '../ImageListFor4Image';
import { Research } from '../Research';

const contenArr6Img = [
    {
        img: aminaTwo,
        title: '@amina_two',
        link: 'https://www.instagram.com/p/CUcPIbeMn6s/',
    },
    {
        img: ampersandsewing,
        title: '@ampersandsewing',
        link: 'https://www.instagram.com/p/CLpcaKFlQ4A/',
    },
    {
        img: makepractice,
        title: '@makepractice',
        link: 'https://www.instagram.com/p/CcLmWrKPjhZ/',
    },
    {
        img: nerrisapratt,
        title: '@nerrisapratt',
        link: 'https://www.instagram.com/p/CMrThKpM_oW/',
    },
    {
        img: slowsewed,
        title: '@slowsewed',
        link: 'https://www.instagram.com/p/CMfaDfFsWgU/',
    },
    {
        img: whitMakes,
        title: '@whit_makes',
        link: 'https://www.instagram.com/p/CVk8tv-LM73/',
    },
];

const imageListFinal = [crossGrain, lapel, movement, colorBlock];

const descrReserch = `Before I choose a fabric, or a size (or sometimes
                    a pattern), I like to sift through blog posts and
                    instagram hashtags for the pattern to see if
                    there's any gotchas or notes I can learn from. Now
                    I can share all this with you through Sew Like
                    Honey!`;

const listResearch = [
    {
        title: `The sleeves are <b>small at the forearm</b>`,
        content: [
            `<a href="https://www.instagram.com/p/CUd76p-P9w_/" target="_blank" rel="noopener noreferrer"> @annaescapist </a> - <em> "My only peeve is that the sleeves are reasonably small in the forearm area so they don’t really work for layering big sleeves under. Still will work for lots of summer clothes though!" </em>`,
            `<a href="https://www.instagram.com/p/CVO-nNIvAGQ/" target="_blank" rel="noopener noreferrer"> @magster_in_melb </a> - <em> "Beyond those sizing tweaks shortened the bodice about 3-4cm and the arms 3cm (too much!) and also trimmed down some of the excess armhole fabric. It was a bit snug through the sleeves as a S to I let a bit of the seam allowance out and that was enough." </em>`,
            `<a href="https://www.instagram.com/p/CYB1d8Nqvsn/" target="_blank" rel="noopener noreferrer"> @ohbelles </a> - <em> "My final adjustments were: -2cm narrow shoulder adjustment - added 3cm to the sleeve width [...] The sleeves are still a bit narrow and a bit short too, but I used fun viscose for the lining so I can roll them up. I think the problem is that the smaller size band isn’t drafted with the amount of sleeve ease that a curvy figure requires, but I’m too small for the larger size band, which I think from the pictures has more ease l, especially in the arms. It’s annoying that they are two separate PDFs - I didn’t get both size ranges printed so I couldn’t compare (but at least you get all the sizes for one pattern cost! I can’t tell you how annoying it is to be a size 16-18 when the smaller size is 6-16 and the larger size is 18-28 and you have to decide which one! I wish companies would overlap them more). I can’t decide if it would be worth muslining the next size up to see if the fit would be better; definitely not any time soon though!" </em>`,
        ],
    },
    {
        title: `Some people thought the <b>shoulders were too big</b> for them`,
        content: [
            `<a href="https://www.instagram.com/p/CTjvo9ovQ2q/" target="_blank" rel="noopener noreferrer"> @adaydreamerlife </a> - <em> "I cut a size S for myself then I realized that the shoulders were too big when I tried it on. So, I added shoulder pads to make the shoulders less sloping. It actually turned out really good. Other than that, I didn’t do any fitting adjustment." </em>`,
            `<a href="https://www.instagram.com/p/CLg5F67l_rF/" target="_blank" rel="noopener noreferrer"> @the.social.fabric </a> - <em> "I brought the shoulders in by 2 cm (3/4”)." [see post for other details] </em>`,
        ],
    },
    {
        title: `Seems like it's <b> better in thinner fabrics like cotton/linen, harder with velvet/corduroy </b> (especially with tighter sleeves)`,
        content: [
            `<a href="https://www.instagram.com/p/CVdk3IYvcJu/" target="_blank" rel="noopener noreferrer"> @ginghamhive </a> - <em> "added more ease in sleeves to account for the thicker fabric and added inseam pockets" </em>`,
            `<a href="https://www.instagram.com/p/CX_h8ctr2i6/" target="_blank" rel="noopener noreferrer"> @the_sew_sew </a> - <em> "I also struggled with the interfacing and wish I hadn’t interfaced the front pieces with a woven. This fabric has enough structure, a knitted one might have been more appropriate. But regardless, every make is a lesson learned and I love the outcome!!" [see post for other details about hemming] </em>`,
            `<a href="https://www.instagram.com/p/CWL_zcUvJPH/" target="_blank" rel="noopener noreferrer"> @sewminisq </a> - <em> " It’s interesting how a different fabric can affect a garment. The tweed is much stiffer than the linen I used in my first blazer. It’s a bit too tight in the arms, but I’ll still wear it. Maybe it will loosen up over time." </em>`,
            `<a href="https://www.instagram.com/p/CLZEuprF5E2/" target="_blank" rel="noopener noreferrer"> @thepowerjen </a> - <em> "This blazer took forever, exclusively because the fabric was a PAIN. I absolutely love it, but it was a pain. In every way imaginable. Messy, shifty, impossible to press etc. The finished blazer is definitely wearable in the end, but not my finest work - I'm particularly unimpressed with the sleeve heads, but as I said the fabric was impossible and this was the best I could do." </em>`,
        ],
    },
];

export const CroppedblazerMain = () => {
    return (
        <Container>
            <Section1 />
            <Divider />
            <Section2 />
            <Divider />
            <Section3 />
            <Divider />
            <Research
                title="Research about other makes"
                textBefore={descrReserch}
                listItems={listResearch}
                subDescr="Analysis/TLDR;"
            />
            <Divider />
            <Section5 />
            <Divider />
            <Section6 />
            <Divider />
            <Section7 />
            <Divider />
            <ImageListFor4Image
                imageList={imageListFinal}
                title="Final photos"
            />
            <Divider />
            <ImageList
                images={contenArr6Img}
                title="Other detailed Heather Blazer makes"
            />
            <Divider />
        </Container>
    );
};
