import React from 'react';
import { Col, Row } from 'react-bootstrap';
import plsToile from '../../../assets/img/pls-toile.png';
import adjustments from '../../../assets/img/adjustments.png';
import notes from '../../../assets/img/notes.jpg';
import './section5.css';
import { PostTitle } from '../../PostTitle';

const contebtArr = [
    { title: "~ here's me begging you to toile ~", img: plsToile },
    { title: '~ adjustments! ~', img: adjustments },
    { title: '~ my notes ~', img: notes },
];

export const Section5 = () => {
    return (
        <>
            <PostTitle title="Alteration time 📏 📝" />
            <Row>
                <Row>
                    After my research, I knew I wanted to (1) Make it
                    easier to raise my arms and (2) Make the sleeves
                    longer so I could use my embroidered ribbon at the
                    cuffs :) Looking back, I would have chosen a
                    sturdier fabric -- this jumpsuit has a stretched a
                    lot since the back is on the bias. I also wish I'd
                    done bias binding instead of a facing.
                </Row>
                {contebtArr.map(({ title, img }, index) => (
                    <Col md={4} key={index}>
                        <Row className="justify-content-center">
                            <Col>
                                <img
                                    className="w-100 post-img"
                                    src={img}
                                    alt={title}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="justify-content-center text-align-center blog-good-stuff">
                                {title}
                            </Col>
                        </Row>
                    </Col>
                ))}
            </Row>
        </>
    );
};
