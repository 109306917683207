import React from 'react';
import { Col, Row } from 'react-bootstrap';
import alterationJourney from '../../../assets/img/alteration_journey.jpg';
import blazerUpperSleeve from '../../../assets/img/blazer_upper_sleeve.png';
import blazerSleeveLining from '../../../assets/img/blazer_sleeve_lining.png';
import blazerSleeveLiningRx from '../../../assets/img/blazer_sleeve_lining_rx.png';

import { PostTitle } from '../../PostTitle';

export const Section7 = () => {
    return (
        <>
            <PostTitle title="Alterations: Fixing arm mobility" />
            <Row>
                <Col>
                    {' '}
                    The only issue I had with my toile was sleeve
                    mobility. I could not move my arms up, and
                    crossing my arms in front of me felt really tight.
                    At first, I thought it's because I used very thick
                    upholstry fabric (thinking I could make it
                    wearable). Then I made the same version in regular
                    muslin material and had similar issues. I tried
                    raising the armhole 3/4", but that did not help
                    much.{' '}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <img
                        className="w-100 post-img"
                        src={alterationJourney}
                        alt="alterationJourney"
                    />
                </Col>
            </Row>
            <Row className="blog-section-title">
                {' '}
                Solution: Full Bicep Adjustment &amp; Increase Elbow
                Width{' '}
            </Row>
            <Row className="justify-content-center">
                <Col className="sleeve-explanation thank-you">
                    I ended up doing a full bicep adjustment on the
                    sleeves, which gave me a lot more room to move
                    around! I followed the{' '}
                    <a
                        href="https://blog.closetcorepatterns.com/adjustments-for-one-and-two-piece-sleeves/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Closet Core Patterns guide for increasing the
                        bicep for two piece sleeves
                    </a>{' '}
                    -- turns out you only have to adjust the upper
                    sleeve! But you need to use the lower sleeve to
                    mark the bicep line.
                    <br />I estimated the elbow room (ha) needed and
                    distributed the extra across the sleeve -- doing
                    the full bicep already added the 3/4" in the
                    middle, so I added an extra 5/8" on top of that at
                    the sides of the sleeve.{' '}
                    <a
                        href="https://sewing.patternreview.com/SewingDiscussions/topic/111121"
                        target="_blank"
                        rel="noopener noreferrer">
                        Here is a sewing discussion on PatternReview
                    </a>{' '}
                    about adding ease at the elbow.
                </Col>
                <Col md={4}>
                    <img
                        className="w-100 post-img"
                        src={blazerUpperSleeve}
                        alt="blazerUpperSleeve"
                    />
                </Col>
                <Col md={4}>
                    <img
                        className="w-100 post-img"
                        src={blazerSleeveLining}
                        alt="blazerSleeveLining"
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <img
                        className="w-100 post-img"
                        src={blazerSleeveLiningRx}
                        alt="blazerSleeveLiningRx"
                    />
                </Col>
                <Col className="sleeve-explanation my-auto">
                    Here's the relationship I found between the lining
                    and main pattern pieces for the upper &amp; lower
                    sleeves. I think this is fairly standard (lining
                    sits higher at armhole, further back from hem),
                    and I'm going to use these measurements to draft
                    sleeve lining for pattern pieces in the future!
                </Col>
            </Row>
        </>
    );
};
