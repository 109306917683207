import React from 'react';
import { Col, Row } from 'react-bootstrap';
import leatherScraps from '../../../assets/img/leather-scraps.jfif';
import leatherLabel from '../../../assets/img/leather-label.jpg';
import { PostTitle } from '../../PostTitle';

export const Section3 = () => {
    return (
        <>
            <PostTitle title="Where do you find leather?" />

            <Row className="m-2">
                <Col>
                    I sourced my leather from{' '}
                    <a
                        href="https://fabscrap.myshopify.com/collections/leather"
                        target="_blank"
                        rel="noopener noreferrer">
                        FabScrap
                    </a>
                    , where I volunteered with a very dear very kind
                    friend who doesn’t sew but cares about textile
                    waste/thrifting. This angel of a friend combined
                    her 5 pounds of free fabric with mine and together
                    we dug through the bin of leather scraps to take
                    home all the black pieces we could find!{' '}
                    <em>
                        {' '}
                        Starting out with scraps felt like an easier
                        way to start a first project working with
                        leather{' '}
                    </em>{' '}
                    The leather I used was labeled “Vegetal Black
                    0.6/0.7 mm” which I think says how thick it is? (I
                    am still new to this!) It had some
                    drape/flexibility to it.
                </Col>
            </Row>
            <Row className="m-10 justify-content-center">
                <Col></Col>
                <Col xs={4}>
                    <img
                        className="w-100"
                        src={leatherScraps}
                        alt="black leather scraps layed next to each other on a wooden floor."
                    />
                    <div className="text-center">
                        allll my leather scraps
                    </div>
                </Col>
                <Col xs={4}>
                    <img
                        className="w-100 inspo-img"
                        src={leatherLabel}
                        alt="label on black leather. Says Vegetal Black 0.6/0.7mm ABA"
                    />
                    <div className="text-center">
                        the piece I used
                    </div>
                </Col>
                <div className="col"></div>
            </Row>
        </>
    );
};
