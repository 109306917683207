import React from 'react';

import { Divider } from '../Divider';
import { Container } from 'react-bootstrap';
import { Section1 } from './Section1';
import { Section2 } from './Section2';
import { Section3 } from './Section3';
import { Section4 } from './Section4';
import { Section5 } from './Section5';
import { ImageList } from '../ImageList';

import final1 from '../../assets/img/final-2.jpg';
import final2 from '../../assets/img/final-3.jpeg';
import final3 from '../../assets/img/final-1.jpg';
import gathered from '../../assets/img/gathered.png';
import wedding from '../../assets/img/wedding.png';
import lace from '../../assets/img/lace.png';
import scallops from '../../assets/img/scallops.png';
import quilt from '../../assets/img/quilt.png';
import silk from '../../assets/img/silk.png';
import { Research } from '../Research';
const contenArr3Img = [
    {
        img: final1,
        title: '',
        link: '',
    },
    {
        img: final2,
        title: '',
        link: '',
    },
    {
        img: final3,
        title: '',
        link: '',
    },
];
const contenArr6Img = [
    {
        img: gathered,
        title: '@caughtupinstitches',
        link: 'https://www.instagram.com/p/CjF2fH5Ok_e/',
    },
    {
        img: wedding,
        title: '@hyegineer_projects',
        link: 'https://www.instagram.com/p/CbvBP_olALb/',
    },
    {
        img: lace,
        title: '@elleandthestitches',
        link: 'https://www.instagram.com/p/CeoS7IsKajg/',
    },
    {
        img: scallops,
        title: '@m.e.r.ma.id',
        link: 'https://www.instagram.com/p/CNVWvuPMt9e/',
    },
    {
        img: quilt,
        title: '@relume_co',
        link: 'https://www.instagram.com/p/CcxwdDGOER2/',
    },
    {
        img: silk,
        title: '@sew.zesty',
        link: 'https://www.instagram.com/p/CSHzm2sB-J1/',
    },
];

const descrReserch = `Before I choose a fabric, or a size (or sometimes a pattern), I like to sift through blog posts and instagram hashtags for the pattern to see if there's any gotchas or notes I can learn from. Here it is! `;

const listResearch = [
    {
        title: `Top of the cups can gape.`,
        content: [
            `Make a toile or two of different cup sizes and baste them in to try different sizes without needing to remake the whole bodice`,
            `I used a <a href="https://blog.closetcorepatterns.com/fitting-balconette-bra-cup-sophie-swimsuit-pattern/" target="_blank" rel="noopener noreferrer"> Closet Core Patterns blog post </a> to help fit my cups.`,
            ` Many sewists recommend <a href="https://www.instagram.com/reel/CamzZGJDoiW/" target="_blank" rel="noopener noreferrer"> @lore.piar's Reel tutorial </a> for adjusting <b>gaping at the top of the cup </b> <a href="https://www.instagram.com/p/CbqAmc3K2Ay/" target="_blank" rel="noopener noreferrer"> @dani_sews_ </a> - <em> " I was a little bit intimidated at first, not gonna lie. I did a toile, adjusted the cup by taking it in by 1cm in the middle (as explained in @lore.piar reel, thank you ❤️) et voila! It fits like a dream 😍 I made a size 32 with a B cup, following my measurements. I widened the side panels by 1 inch, modified the cup slightly and shortened it by 1/2 inch as it’s a top. It’s closed with a lace up back, has foam cups and boning along the seams" </em>`,
            `<a href="https://www.instagram.com/p/CfBi-T5Mqz8/" target="_blank" rel="noopener noreferrer"> @vrouwlijn_wonderlijk </a> - <em> "I made a size 38 with a C cup and lengthened the body by 6cm. [...] Even after stay stitching and using fusible interfacing, the top edge of the cups were gaping a little bit. I added some elastic (from an old bra-kit I bought ages ago) to the inside and handstitched a lace ribbon to the outside and that did the trick. Furthermore, I attached two straps to give some extra support 😉. All in all, I think this is an incredible pattern and the designer offers a great video tutorial in case the written instructions aren’t enough." </em>`,
        ],
    },
    {
        title: `Boning &amp; underwire make a difference`,
        content: [
            `<a href="https://www.instagram.com/p/Cd_re4KKkag/" target="_blank" rel="noopener noreferrer"> @sandeepbeep </a> - <em> "For some silly reason I only toiled one foam cup, but perhaps I should have toiled them both because my dress zip popped on my final garment and that’s mostly down to using a cheap zip, but also there’s a chance it may have been a bit snugger than expected. More on that later. In this series of pictures you can see 1. Without boning and underwires. 2. With boning, underwires and one added foam cup. I don’t think I’m too fussed by having foam cups. I definitely need it on the lilac dress as the fabric is sheer and light, but if I were to make the dress again, which I plan to do, then I’ll probably skip the foam cups next time." </em>`,
            `<a href="https://www.instagram.com/p/CjfSP2fPJy0/" target="_blank" rel="noopener noreferrer"> @britasews </a> - <em> "I made it in a 6D. This is my third go at it (two toiles before this dress) so there were a few fitting issues I needed to iron out: mainly the upper cup needed about 2cm taken out and some tweaks to bring the bodice in a little. As per a lot of versions I’ve seen on here, I added the centre front seam, boned every seam, and added foam and underwire to the cup. Also, since this viscose is very drapey and quite light and shifty, I underlined the bodice in calico." </em>`,
        ],
    },
    {
        title: `Many sewists posted alterations/fitting journeys:`,
        content: [
            `<a href="https://www.instagram.com/p/CiIqaX4v3DR/" target="_blank" rel="noopener noreferrer"> @seriicis </a> Documented her fitting process in her stories. This fitting journey motivated me to get started fitting my bodice!`,
            `<a href="https://www.instagram.com/p/CjB7N1WIxyC/" target="_blank" rel="noopener noreferrer"> @amalia.animalia </a> made a bust cast to try to fit the bodice!`,
            `<a href="https://www.instagram.com/p/Cgeug_KPahG/" target="_blank" rel="noopener noreferrer"> @amytlong </a> describes the three versions she made in her fitting process.`,
        ],
    },
    {
        title: `Don't skip interfacing!`,
        content: [
            `<a href="https://www.instagram.com/p/CSRwZcQrmSM/" target="_blank" rel="noopener noreferrer"> @bdhandmadeottawa </a> - <em> "I previously didn't understand the type of interfacing used on the bodice because I had never worked with interfacing like that before. The pattern designer said it's sometimes called tailor's interfacing but I found more success with the term "weft interfacing." Next, I learned my lesson to take the designers word as gospel. Do not skip steps. Use interfacing, use stay tape. I even tried her method of inserting an invisible zipper and it's wonderful. Trust her! In addition to the instructions, I watched the YouTube video, and the hack video for foam cups/underwire. I definitely think the foam cups and underwire are necessary for me (personal preference)." </em>`,
            `<a href="https://www.instagram.com/p/CjQdRcaL2zx/" target="_blank" rel="noopener noreferrer"> @m.e.r.ma.id </a> - <em> "Here's my 5th #rosecafebustier 🥰 I removed 1/2 cm from the upper cup piece to reduce the pointiness of the cups. They are still a bit pointy, but two rows of topstitching did the trick. I made sure to interface both the lining and main cups with medium-weight interfacing since the fabric is lightweight. I usually have the best result when the fabric is more on the medium/heavy side, but interfacing the cups helped." </em>`,
        ],
    },
    {
        title: `Here are other blog posts about the pattern:`,
        content: [
            `ClassNamey Sassy Pearl: <a href="https://classNameysassypearl.com/sewing-pattern-review-rose-cafe-bustier-by-daria-pattern-making/" target="_blank" rel="noopener noreferrer"> "Sewing Pattern Review: Rose Cafe Bustier by Daria Pattern Making" </a>`,
            `Breath of Fresh Wear: <a href="http://www.breathoffreshwear.com/2022/09/rose-cafe-bustier-m7971.html" target="_blank" rel="noopener noreferrer"> "ROSE CAFE BUSTIER + M7971" </a>`,
            `Tammy Handmade: <a href="https://www.tammyhandmade.com/rose-cafe-bustier-dress-sewing-pattern-review/" target="_blank" rel="noopener noreferrer"> "Rose Cafe Bustier Dress Sewing Pattern Review" </a>`,
            `Monde Chisenga: <a href="https://mondechisenga.com/ankarabustier/" target="_blank" rel="noopener noreferrer"> "Rose Cafe Bustier With a Touch of Ankara" </a>`,
            ` Ashley The Sewcialite: <a href="https://www.ashleythesewcialite.com/blog/fail-denim-bustier-featuring-rose-cafe-bustier-dress-pattern" target="_blank" rel="noopener noreferrer"> "FAIL: Denim Bustier featuring the Rose Cafe Dress" </a>`,
            `eigenmotion: <a href="https://www.eigenmotion.ca/eigenmotion/2022/3/26/b7krw0gpwvqfpqwbafhk2e3ol1gs44" target="_blank" rel="noopener noreferrer"> "Rose Cafe Bustier" </a>`,
            `Sew Star Tino: <a href="https://sewstartino.com/2021/08/27/making-my-first-formal-dress/" target="_blank" rel="noopener noreferrer"> "Making my first formal dress!" </a>`,
            `Looks Like I Made It: <a href="http://looks-like-i-made-it.blogspot.com/2021/07/daria-patternmaking-rose-cafe-bustier.html" target="_blank" rel="noopener noreferrer"> "Daria Patternmaking, Rose Cafe Bustier"`,
        ],
    },
];

export const LeatherbustierMain = () => {
    return (
        <Container>
            <Section1 />
            <Divider />
            <Section2 />
            <Divider />
            <Section3 />
            <Divider />
            <Section4 />
            <Divider />
            <Section5 />
            <Divider />
            <Research
                title="Research about other makes"
                textBefore={descrReserch}
                listItems={listResearch}
                subDescr="Analysis/TLDR;"
            />
            <Divider />
            <ImageList images={contenArr3Img} title="Final photos" />
            <Divider />
            <ImageList
                images={contenArr6Img}
                title="Other Rose Cafe Bustier makes"
            />
            <Divider />
        </Container>
    );
};
