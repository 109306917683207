import React from 'react';
import { Col, Row } from 'react-bootstrap';
import sticky from '../../../assets/img/sticky.jpeg';
import ladyGrey from '../../../assets/img/lady-grey.jpeg';

import './section10.css';
import { PostTitle } from '../../PostTitle';

export const Section10 = () => {
    return (
        <>
            <PostTitle title="Construction notes" />
            <Row className="justify-content-center">
                <Col>
                    <img
                        className="w-100 post-img"
                        src={sticky}
                        alt="sticky"
                    />
                </Col>
                <Col className="sleeve-explanation my-auto">
                    The pattern's original seam allowance was 3/8",
                    but I wanted to be able to French Seam the back
                    and side pieces, so I added 1/4" to under the
                    sleeves, the side seam, and the back seam. Where
                    the pants connect with the top, I used a sticky
                    note to mark the 2" on my machine since my seam
                    guide doesn't go that wide.
                </Col>
                <Col>
                    <img
                        className="w-100 post-img"
                        src={ladyGrey}
                        alt="ladyGrey"
                    />
                </Col>
            </Row>
        </>
    );
};
