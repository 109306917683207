import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './header.css';

import logo from '../../assets/img/logo.png';
import insta from '../../assets/img/insta-logo-yellow.svg';
import rLogo from '../../assets/img/r-logo-white.svg';
import { SHOW_NAV as showNav } from '../../config';

export const Header = () => {
    const location = useLocation();
    return (
        <header
            className={`header-container ${
                !showNav.includes(location.pathname) &&
                'header-container-mod-padding-bottom'
            }`}>
            <Link to="/">
                <img
                    src={logo}
                    alt="sew like honey logo of honey bear holding needle and thread"
                    className="header-logo"
                />
            </Link>
            <div className="title-container">
                <div className="main-title">Sew Like Honey</div>
                <div className="sub-title">
                    long-lasting, natural, created with care{' '}
                </div>
                {showNav.includes(location.pathname) && (
                    <div className="links-title">
                        <Link className="header-link" to="about">
                            <span>About</span>
                        </Link>
                        <span>|</span>
                        <Link className="header-link" to="signup">
                            <span>Newsletter</span>
                        </Link>
                        <span>|</span>
                        <a
                            className="title-link-wrapper"
                            href="https://www.instagram.com/sew.like.honey/"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={insta} alt="instagram logo" />
                        </a>
                        <a
                            className="title-link-wrapper"
                            href="https://www.ravelry.com/people/grayseabay"
                            target="_blank"
                            rel="noopener noreferrer">
                            <img src={rLogo} alt="ravlery logo" />
                        </a>
                    </div>
                )}
            </div>
        </header>
    );
};
