import React from 'react';
import { Col, Row } from 'react-bootstrap';
import waistlineAdjustments from '../../../assets/img/waistline-adjustments.png';
import waistPattern from '../../../assets/img/waist-pattern.png';

import './section9.css';
import { PostTitle } from '../../PostTitle';

export const Section9 = () => {
    return (
        <>
            <PostTitle title="Alterations: Adding length to the waist" />
            <Row className="justify-content-center">
                <Col>
                    <img
                        className="w-100 post-img"
                        src={waistlineAdjustments}
                        alt="waistlineAdjustments"
                    />
                </Col>
                <Col className="sleeve-explanation my-auto">
                    In my bedsheet toile, I had trouble lifting my arm
                    because the sleeve is part of the waist piece, and
                    there wasn't enough length for me. I added 1.5" at
                    the waist, but only at the side waist, to give me
                    better arm movement. I just blended it into the
                    original waistline at the CF. Because my fabric
                    was drapey, the extra ease at the side wasn't very
                    noticeable
                </Col>
                <Col lg={6}>
                    <img
                        className="w-100 post-img"
                        src={waistPattern}
                        alt="waistPattern"
                    />
                </Col>
            </Row>
        </>
    );
};
