import React from 'react';
import { Container, Row } from 'react-bootstrap';
import './clickImage.css';

export const ClickImage = () => {
    return (
        <Container fluid className="click-image-container">
            <Row className="margin-sides">
                <div className="callout">
                    <em>click an image to open its blog post</em>
                </div>
            </Row>
        </Container>
    );
};
