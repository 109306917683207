import React from 'react';
import {
    CroppedblazerMain,
    PostEnd,
    PostTitleContainer,
} from '../../components';

const croppedblazerBlog = {
    title: 'Heather Blazer Pattern Hack: Cropped!',
    date: 'May 31, 2022',
    slogan: 'also with extra arm movement',
    linkTitle: 'Heather Blazer Pattern',
    link: 'https://fridaypatterncompany.com/products/heather-blazer-pdf-pattern',
};

export const Croppedblazer = () => {
    return (
        <>
            <PostTitleContainer data={croppedblazerBlog} />
            <CroppedblazerMain />
            <PostEnd />
        </>
    );
};
