import React from 'react';
import { Col, Row } from 'react-bootstrap';
import cuffMeasurement from '../../../assets/img/cuff-measurement.png';
import cuff from '../../../assets/img/cuff.png';
import hotChocolate from '../../../assets/img/hot-chocolate.jpg';
import './section7.css';
import { PostTitle } from '../../PostTitle';

const contentArr = [cuffMeasurement, cuff, hotChocolate];

export const Section7 = () => {
    return (
        <>
            <PostTitle title="Alterations: Adding a cuff" />
            <Row>
                <Col>
                    I extended the sleeve 1", and then I drafted a
                    2.25" wide folded over cuff to add a little more
                    length and perfectly fit the embroidered ribbon.
                    Looking at this diagram, I drew the fold line at
                    the wrong spot, it should be at the top! Note that
                    I also left seam allowance on one side of the cuff
                    so that it could be sewn together. The ribbon is
                    2", so the 1/4" leaves a little wiggle room for it
                    to sit nicely on the cuff.
                </Col>
            </Row>
            <Row className="justify-content-center">
                {contentArr.map((img, i) => (
                    <Col lg={4} key={i}>
                        <img
                            className="w-100 post-img"
                            src={img}
                            alt="TODO"
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
