import React from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import './section4.css';
import rosecafeThumbnail from '../../../assets/img/rosecafe-thumbnail.png';
import leatherMark from '../../../assets/img/leather-mark.jpg';
import practice from '../../../assets/img/practice.png';
import preTopstitch from '../../../assets/img/pre-topstitch.png';
import postTopstitch from '../../../assets/img/post-topstitch.jpg';
import cups from '../../../assets/img/cups.png';
import cups2 from '../../../assets/img/cups-2.png';
import back from '../../../assets/img/back.jpg';
import front from '../../../assets/img/front (1).jpg';
import { PostTitle } from '../../PostTitle';

export const Section4 = () => {
    return (
        <>
            <PostTitle title="Sewing process" />

            <Row className="m-2">
                <Col xs={4}>
                    <img
                        className="w-100 inspo-img"
                        src={rosecafeThumbnail}
                        alt="Youtube thumbnail for Rose Cafe bustier dress sewing tutorial Boned Bustier with Foam Cups"
                    />
                    <div className="text-center">
                        <a
                            href="https://www.youtube.com/watch?v=6J2Ndd7vKFU"
                            target="_blank"
                            rel="noopener noreferrer">
                            youtube tutorial
                        </a>
                    </div>
                </Col>
                <Col>
                    I followed Daria's{' '}
                    <a
                        href="https://www.youtube.com/watch?v=6J2Ndd7vKFU"
                        target="_blank"
                        rel="noopener noreferrer">
                        “Boned Bustier with Foam Cups”
                    </a>{' '}
                    tutorial to add foam cups and boning. The boning
                    helped give the leather some extra structure.
                </Col>
            </Row>

            <Row>
                {' '}
                <p className="blog-section-subheading">
                    {' '}
                    &#123;Prepping the pieces&#125;{' '}
                </p>
            </Row>

            <Row className="m-2">
                <Col xs={4}>
                    <img
                        className="w-100 inspo-img"
                        src={leatherMark}
                        alt="Gracie's hand holding a white pencil over a piece of black leather with pattern pieces marked"
                    />
                    <div className="text-center">
                        marked pattern piece
                    </div>
                </Col>
                <Col>
                    To mark the leather, I used a white colored pencil
                    to trace out the pattern pieces. Leather doesn’t
                    have “grain lines”, according to my shoe-making
                    teacher, instead different parts of the animal
                    skin have different levels of stretch (i.e. belly
                    pieces are stretchier because they stretch on the
                    animal). She also said places tend to not give
                    people those pieces? So in general it’s okay to
                    play pattern tetris ignoring grainlines when
                    laying pieces out. But take this with a grain of
                    salt because I am *not* an expert! (If you know
                    more about leather, please reach out to me and
                    correct me if I’m wrong)
                </Col>
            </Row>

            <Row className="m-2">
                <Col>
                    <br />
                    What worked for my machine (Singer Heavy Duty
                    4423) was a stitch length at 3, a heavy duty
                    needle, and a teflon foot. I practiced on a scrap
                    piece of leather folded in half to make sure my
                    machine could handle more than one layer. Once
                    this worked, I sewed the bodice pieces together.
                </Col>
                <Col xs={4}>
                    <img
                        className="w-100 inspo-img"
                        src={practice}
                        alt="Pratice on Scrap leather. Used a teflon foot to glide over the leather. Used a big stitch length (3 ish). &amp; used a heavy duty/leather needle"
                    />
                    <div className="text-center">
                        graphic from my insta post
                    </div>
                </Col>
            </Row>

            <Row>
                {' '}
                <p className="blog-section-subheading">
                    {' '}
                    &#123;Sewing the bodice&#125;{' '}
                </p>
            </Row>

            <Carousel fade indicators={false} interval={5000}>
                <Carousel.Item>
                    <img
                        src={preTopstitch}
                        className="d-block w-100"
                        alt="preTopstitch"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src={postTopstitch}
                        className="d-block w-100"
                        alt="postTopstitch"
                    />
                </Carousel.Item>
            </Carousel>

            <Row className="m-2">
                <Col>
                    Sewing the bodice together was my FAVORITE PART!
                    The leather was smooth and easy enough to get
                    through my machine. On each seam, I started about
                    an inch in to make it easier to start the seam.
                    When I finished sewing from the inch-in point to
                    the end, I flipped the piece over and started a
                    few stitches before the inch and sewed the last
                    inch to the end. After sewing the bodice pieces
                    together, I sewed the polyester rigiline to the
                    seam allowance, centering it on the seam and
                    leaving about a half inch on either seam edge free
                    of boning. Then I topstitched the seam allowance
                    with the boning into place. You can see I took off
                    width in the CF piece and it just barely fits the
                    seam allowance on either side!
                </Col>
            </Row>

            <Row>
                {' '}
                <p className="blog-section-subheading">
                    {' '}
                    &#123;Sewing the cups&#125;{' '}
                </p>
            </Row>

            <Row className="m-2">
                <Col>
                    Sewing the cups was next. Constructing the cups
                    themselves was not too hard, but I really
                    struggled fitting them into the bodice without
                    pins 🙀 This time I did remember to{' '}
                    <em>
                        adjust the foam cups by taking out seam
                        allowance at the center of the pieces
                    </em>{' '}
                    likes Daria recommends in the video, and that made
                    the cups much smoother!
                </Col>
            </Row>

            <Row className="m-10 justify-content-center">
                <Col></Col>
                <Col xs={4}>
                    <img
                        className="w-100 inspo-img"
                        src={cups}
                        alt="cups"
                    />
                    <div className="text-center">using clips</div>
                </Col>
                <Col xs={4}>
                    <img
                        className="w-100 inspo-img"
                        src={cups2}
                        alt="cups2"
                    />
                    <div className="text-center">
                        fitting into bodice
                    </div>
                </Col>
                <Col></Col>
            </Row>

            <Row className="m-2">
                <Col>
                    The “hack” that I used on the second cup was to{' '}
                    <em>
                        {' '}
                        start topstitching the center side of the cup
                        *first* near the point it meets on the bodice
                        to make sure that stays aligned.
                    </em>{' '}
                    As you can see in my photo above, the top
                    left/right points of the cup can then be
                    pivoted/maneuvered under the bodice to make them
                    align properly. But if you start topstitching at
                    the top part of both sides of the cup, you have no
                    wiggle room!
                    <br />
                    <br />I{' '}
                    <em>
                        hand-stitched the underwire channeling
                        overlapping the cup/bodice edge
                    </em>
                    . Hand-sewing gave me extra control over where the
                    under-channeling sat, and it let me pick up
                    stitches through just the lining and not the
                    leather.
                    <br />
                    <br />
                    One of my leather fears was that it had no
                    forgiveness and all holes would poke through.
                    However, when I messed up and had to re-do some
                    topstitching, it wasn’t as hard as I thought it
                    would be to follow about the same line. Just don’t
                    look too closely lol.
                </Col>
            </Row>

            <Row>
                {' '}
                <p className="blog-section-subheading">
                    {' '}
                    &#123;Final touches&#125;{' '}
                </p>
            </Row>
            <Row className="m-10 justify-content-center">
                <Col xs={6}>
                    <img
                        className="w-100 inspo-img"
                        src={back}
                        alt="back"
                    />
                    <div className="text-center">
                        lace up back, spot the{' '}
                        <a
                            href="https://www.sewanonymous.co.uk/product-page/peekaboo-cat-woven-silhouette-label"
                            target="_blank"
                            rel="noopener noreferrer">
                            Peekaboo Cat label
                        </a>{' '}
                    </div>
                </Col>
                <Col xs={6}>
                    <img
                        className="w-100 inspo-img"
                        src={front}
                        alt="front"
                    />
                    <div className="text-center">DONE!</div>
                </Col>
            </Row>

            <Row className="m-2">
                <Col>
                    I created the laceup back by sandwiching eyelet
                    trim in between the lining and center back pieces,
                    and I used rolled elastic I had on hand to lace it
                    up. I wanted a lace-up back because I wanted
                    adjustable sizing for something I put *so* much
                    effort into!
                    <br />
                    <br />
                    DONE!! I’m excited to play around with how to wear
                    it.
                </Col>
            </Row>
        </>
    );
};
