import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './section1.css';
import valleyMain from '../../../assets/img/valley-main.jpg';

export const Section1 = () => {
    return (
        <>
            <Row className="justify-content-center">
                <Col xs={6} md={5} className="flex">
                    <img
                        className="w-100 post-img"
                        src={valleyMain}
                        alt="Gracie wears a black crepe jumpsuit with gold beaded embroidery belt and cuffs, holding a black beaded clutch, she's standing in front of a stone balcony"
                    />{' '}
                    <br />
                    <span className="post-img-caption">📍Paris </span>
                </Col>
                <Col xs={12} md={5}>
                    <p className="blog-post-intro-text">
                        I traveled to Paris in May 2022 (what how was
                        that so long ago?!), and I wanted to make
                        something special but comfy for the trip. When
                        poking around in my stash, I rediscovered a
                        gold beaded silk chiffon black ribbon I’ve had
                        since one of my first{' '}
                        <a href="https://fabscrap.org/">FabScrap</a>{' '}
                        trips in 2019.
                        <br />
                        <br />
                        The ribbon is 2” wide, and I knew I’d have
                        enough for a waistband and cuffs. I wanted the
                        ribbon to be the statement of the garment and
                        let the rest be muted. At first, I was going
                        to make a blouse, but the embroidery felt a
                        little too heavy/intricate for an everyday
                        kind of blouse :) I found the{' '}
                        <a href="https://peppermintmag.com/sewing-school/valley-jumpsuit/">
                            Peppermint Valley Jumpsuit
                        </a>{' '}
                        and realized it would be the perfect fit for
                        my embroidery ribbon with the thicker waist
                        and opportunity for big sleeve cuffs.
                        <br />
                        <br />
                        For the fabric, I used a few yards of black
                        cotton crepe fabric from FabScrap, too.{' '}
                        <em>
                            Looking back, I wish I used a heavier
                            weight material. The back of the top ends
                            up cut on the bias, so it’s stretched out
                            a good amount
                        </em>
                    </p>
                </Col>
            </Row>
        </>
    );
};
