import React from 'react';
import { Col, Row } from 'react-bootstrap';
import crewNeck from '../../../assets/img/crew-neck.jpg';
import facing from '../../../assets/img/facing.png';
import constructFacing from '../../../assets/img/construct-facing.png';
import './section6.css';
import { PostTitle } from '../../PostTitle';

const contentArr = [crewNeck, facing, constructFacing];

export const Section6 = () => {
    return (
        <>
            <PostTitle title="Alterations: Crew neck & Facing" />
            <Row>
                <Col>
                    Here's how I changed the pattern to create a
                    crew-neck, and add a facing. To adjust the
                    crew-neck: At the shoulder point, I brought the
                    neckline in about 1.25". Then I used my 🇫🇷French
                    Curve🇫🇷 to blend the neckline back into the
                    original neckline at the back and front. To make
                    the facing: I placed a sheet of tracing paper on
                    top of the original pattern and traced the new
                    neckline. Then I measured 2.75" down at the CF and
                    CB. Then I brought that line across the whole
                    neckline. Note that I made the facing stop at CF,
                    not at the end of the front of the pattern (that
                    gets folded over!) When sewing the facing, I made
                    sure the facing got encased in the folded over
                    button extension.
                </Col>
            </Row>
            <Row className="justify-content-center">
                {contentArr.map((img, i) => (
                    <Col lg={4} key={i}>
                        <img
                            className="w-100 post-img"
                            src={img}
                            alt="TODO"
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
