import React from 'react';
import { Col, Row } from 'react-bootstrap';
import buttonSize from '../../../assets/img/button-size.jpg';
import originalButtonfront from '../../../assets/img/original-buttonfront.jpg';
import buttonFront from '../../../assets/img/button-front.png';
import './section8.css';
import { PostTitle } from '../../PostTitle';

export const Section8 = () => {
    return (
        <>
            <PostTitle title="Alterations: Narrow button band" />
            <Row>
                <Col>
                    I wanted to use these gold buttons I also got at
                    FabScrap :) The button was only 3/8" wide, which
                    was much too small for the existing button band. I
                    decreased the width of the button front to be 3/8"
                    to center the button, 3/8" x2 for the interfacing
                    that gets folded underneath, and 3/8" seam
                    allowance to get folded under and sewn down = 2"
                    total from CF (see diagram below for the
                    breakdown).
                </Col>
            </Row>
            <Row className=" justify-content-center">
                <Col>
                    <img
                        className="w-100 post-img"
                        src={buttonSize}
                        alt="buttonSize"
                    />
                </Col>
                <Col>
                    <img
                        className="w-100 post-img"
                        src={originalButtonfront}
                        alt="Clear ruler held over wrist to measure distance from sleeve to wrist."
                    />
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <img
                        className="w-100"
                        src={buttonFront}
                        alt="buttonFront"
                    />
                </Col>
                <Col className=" sleeve-explanation my-auto">
                    Here's how I re-drafted the button front! I made
                    sure to center the button on CF, and made 3/8" on
                    either side. Then I added an extension for
                    Interfacing (3.8" x 2), and added a 3/8" seam
                    allowance to fold under &amp; topstitch.
                </Col>
            </Row>
        </>
    );
};
