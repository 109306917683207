import React from 'react';
import { Col, Row } from 'react-bootstrap';
import patternhack from '../../../assets/img/patternhack (1).png';
import blazerFrontRx from '../../../assets/img/blazer_front_rx.png';
import blazerBackLiningRx from '../../../assets/img/blazer_back_lining_rx.png';

import { PostTitle } from '../../PostTitle';

export const Section6 = () => {
    return (
        <>
            <PostTitle title="Pattern hack" />
            <Row>
                <Col md={4}>
                    <Row className="justify-content-center">
                        <Col>
                            <img
                                className="w-100 post-img"
                                src={patternhack}
                                alt="TODO"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="justify-content-center text-align-center blog-good-stuff">
                            ~ and now it's time for the good stuff ~
                        </Col>
                    </Row>
                </Col>
                <Col md={8} className="my-auto">
                    To create the cropped blazer, I shortened the
                    pieces :) The tricky part was keeping the same
                    relationship between lining and main pieces.
                    <br />
                    <ol>
                        <li>
                            Trace the front/back main &amp; lining
                            pieces so you can cut &amp; mark them
                            #teamTrace
                        </li>
                        <li>
                            Decide on final length.{' '}
                            <em>
                                {' '}
                                for me, this was a few inches below my
                                natural waist{' '}
                            </em>
                        </li>
                        <li>
                            Mark final length on front and back
                            pattern pieces.{' '}
                            <em>
                                {' '}
                                I did this by marking a line on the
                                front piece of my toile, and then
                                copying that mark to the back,
                                "walking" the back piece down the
                                front until the mark{' '}
                            </em>
                        </li>
                        <li>
                            Measure the distance between new length
                            mark and bottom of the hem, call this
                            DISTANCE_DIFF.
                            <ul>
                                <li>
                                    <em>
                                        here's the tricky part,
                                        maintaining the hem
                                        relationship between the
                                        pieces
                                    </em>
                                </li>
                                <li>
                                    The bottom 1 3/8" of the main
                                    fabric will be folded up. So the
                                    amount you want to cut from your
                                    length is (DISTANCE_DIFF - 1 3/8")
                                    out of the middle of each piece.
                                </li>
                                <li>
                                    I think taking the same amount out
                                    from the middle of each piece
                                    (front main, front facing, side
                                    front lining, back main, back
                                    lining), will mean you don't have
                                    to re-trace out/think about the
                                    hem
                                </li>
                            </ul>
                        </li>
                    </ol>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <img
                        className="w-100 post-img"
                        src={blazerFrontRx}
                        alt="blazerFrontRx"
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={8}>
                    Here's my front pieces annotated. Well actually
                    it's the lengthened pieces annotated (pretend it's
                    the cropped version, same concept since we're just
                    looking at the hem LOL)
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={8}>
                    <img
                        className="w-100 post-img"
                        src={blazerBackLiningRx}
                        alt="blazerBackLiningRx"
                    />
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg={8}>
                    And the back lining. Now in my actual version, my
                    back lining was too long -- so I either cut
                    something out wrong or the lining should be more
                    than 3/4" or 1" shorter -- take this with some
                    salt :) and if you figure this out message me and
                    I'll update this!
                </Col>
            </Row>
        </>
    );
};
