import React from 'react';
import logo from '../../assets/img/logo.png';
import './postEnd.css';
import { Col, Container, Row } from 'react-bootstrap';

export const PostEnd = () => {
    return (
        <Container className="mb-5">
            <Row>
                <Col md={3}>
                    <img
                        className="w-100 post-img"
                        src={logo}
                        alt="logo"
                    />
                </Col>
                <Col className="blog-post-intro-text space-above">
                    Thanks for reading{' '}
                    <span className="honey-underline">
                        Sew Like Honey
                    </span>{' '}
                    post! I hope this was helpful in your making
                    journey :) I haven't built in comments or a
                    "message me" feature yet, but if you have any
                    feedback, please share it through my instagram{' '}
                    <a
                        href="https://www.instagram.com/sew.like.honey/"
                        target="_blank"
                        rel="noopener noreferrer">
                        @sew.like.honey
                    </a>
                    . Or at <b>sewlikehoney</b> &lt;[ at ]&gt; gmail.
                    <br />
                    <br />
                    Special thanks to my friend Michelle for designing
                    my sewing honey bear logo 🍯🐻
                </Col>
            </Row>
        </Container>
    );
};
