import React from 'react';
import { Col, Row } from 'react-bootstrap';
import underwire from '../../../assets/img/underwire.png';
import { PostTitle } from '../../PostTitle';
import './section5.css';

export const Section5 = () => {
    return (
        <>
            <PostTitle title="Tools & supplies that helped me" />
            <ul>
                <li>
                    <em>Underwire:</em> Underwire works *wonders*.
                    Toile with underwires &amp; foam if you’re using
                    it. Without underwire, the front gaped a good 3-4
                    inches from my breast bone. With underwire, it
                    pretty much sits flat!
                    <ul>
                        <li>
                            Try a few different underwire sizes and
                            shapes. I tried the 36 and 38 from{' '}
                            <a
                                href="https://www.lilypadesigns.com/shop/underwires-wide-vertical"
                                target="_blank"
                                rel="noopener noreferrer">
                                Lilypa Designs
                            </a>{' '}
                            in "Wide Vertical" shape.
                            <br />
                            <img
                                src={underwire}
                                alt="underwire"
                                className="img-underwire"
                            />
                        </li>
                    </ul>
                </li>
                <li>
                    <em>Foam:</em> I used 1/8" thick foam from{' '}
                    <a
                        href="https://www.lilypadesigns.com/shop/cut-n-sew-foam"
                        target="_blank"
                        rel="noopener noreferrer">
                        Lilypa Designs
                    </a>
                    . Thank you{' '}
                    <a
                        href="https://www.instagram.com/bygraciesteel/"
                        target="_blank"
                        rel="noopener noreferrer">
                        @bygraciesteel
                    </a>{' '}
                    for giving me advice about what foam to try!
                    <ul>
                        <li>
                            If you're sewing foam cups, make sure to
                            follow{' '}
                            <a
                                href="https://youtu.be/6J2Ndd7vKFU?t=1077"
                                target="_blank"
                                rel="noopener noreferrer">
                                this part
                            </a>{' '}
                            of Daria's video to alter the cup pattern
                            to remove seam allowance from the center
                            of the cup to reduce bulk &amp; be able to
                            attach them with a zig zag. This
                            construction was so nice.
                        </li>
                    </ul>
                </li>
                <li>
                    <em>Boning:</em> I bought Rigiline (1/4” wide) in
                    the NYC Garment District, but it came from a box
                    that looks{' '}
                    <a
                        href="https://www.amazon.com/50yds-Rigilene-Poly-Polyester-Boning/dp/B00MQ8TGZY"
                        target="_blank"
                        rel="noopener noreferrer">
                        similar to this
                    </a>
                </li>
            </ul>
        </>
    );
};
