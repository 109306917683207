import React from 'react';
import { Col, Row } from 'react-bootstrap';
import rowing from '../../../assets/img/rowing.gif';

export const Section1 = () => {
    return (
        <Row className="justify-content-center">
            <Col xs={6} md={5} className="flex">
                <img
                    className="w-100 post-img"
                    src={rowing}
                    alt="Gracie wears a cropped blazer with wallpaper-like fabric while rowing a boat, displaying arm mobility"
                />
                <span className="post-img-caption">
                    📍Château de Fontainebleau{' '}
                </span>
            </Col>
            <Col xs={12} md={5}>
                <p className="blog-post-intro-text">
                    WOO! Look at this arm movement! After 3 months of
                    on/off toiling I've finally finished the first
                    version of my Heather Blazer.
                    <br />
                    <br />
                    This post shares all my research &amp; notes
                    throughout th process. I first bought the pattern
                    around its launch in January 2021, thinking I
                    would make a lengthened-to-my-ankles coat, but
                    fast-forward to February 2022 &amp; spring fever
                    changed my plans. Read on to find fit adjustments,
                    other makes that I learned from, and links to
                    tips/blog posts I used to make a full bicep
                    adjustment.
                </p>
            </Col>
        </Row>
    );
};
