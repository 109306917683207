import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import aboutImg from '../../assets/img/about.jpg';
import aboutImgSquare from '../../assets/img/about_square.png';
import './aboutMain.css';

export const AboutMain = () => {
    return (
        <Container>
            <Row className="justify-content-center about-me">
                <Col xs={6} md={5}>
                    <Row>
                        <img
                            className="about-img"
                            src={aboutImg}
                            alt="Gracie stands wearing natural canvas Yanta Overalls with a cup of tea in front of a sewing machine and a wall with thread mounted"
                        />
                        <img
                            className="about-img-square"
                            src={aboutImgSquare}
                            alt="Gracie stands wearing natural canvas Yanta Overalls with a cup of tea in front of a sewing machine and a wall with thread mounted"
                        />
                    </Row>
                </Col>
                <Col xs={12} md={7}>
                    <p>
                        Hi! I’m Gracie, a maker based in NYC. Sew Like
                        Honey is my maker’s diary/sewing blog
                        exploring how to create unique, special,
                        long-lasting garments with care that will
                        hopefully last as long as a jar of honey 🐝🍯
                        and um may take as long to make as a jar of
                        honey, too -- I am a slooooow sewer.
                    </p>

                    <p>
                        But maybe sewing slowly is a good thing?
                        Although I’ve been thrifting for a long time,
                        I only started learning about the dangers of
                        fast fashion after reading{' '}
                        <a href="https://www.goodreads.com/book/show/43671670-fashionopolis">
                            Fashionopolis
                        </a>{' '}
                        in 2020. Around the same time, I discovered
                        the{' '}
                        <a href="https://lovetosewpodcast.com/">
                            Love to Sew
                        </a>{' '}
                        podcast and decided to try to learn how to sew
                        my own clothes through osmosis. It wasn’t
                        until November that year that I picked up a
                        sewing machine at Goodwill -- but the podcast
                        gave me the right vocabulary to search my way
                        around the internet to learn what I needed to!
                    </p>

                    <p>
                        I like working with secondhand materials. When
                        I started sewing garments, I was completely
                        overwhelmed with the number of options! What
                        kind of garment? Which pattern? What material?
                        What print/color? Constraining myself to
                        working with secondhand materials as much as
                        possible helped me break through some of this
                        initial overload of creative options :)
                    </p>

                    <p>
                        Overall, I hope this blog is a helpful archive
                        for you in your creating journey. I like to do
                        a lot of research on a pattern/idea before I
                        get started, and I hope I can share what I’ve
                        learned with you!
                    </p>

                    <p>
                        Happy making,
                        <br />
                        Gracie
                    </p>
                </Col>
            </Row>
        </Container>
    );
};
