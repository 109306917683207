import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './section4.css';
import { PostTitle } from '../../PostTitle';

export const Section4 = () => {
    return (
        <>
            <PostTitle title="Choosing a size" />
            <Row>
                <Col>
                    My measurements at the time of making this were B
                    37" / W 30" / H 37". According to the{' '}
                    <a href="/img/size-chart.png" download="">
                        {' '}
                        size chart
                    </a>
                    :
                    <ul>
                        <li>
                            Size 38 is B (35 7/16"), W (27 9/16"), H (
                            <span className="blog-matching-measurement">
                                38 25/64"
                            </span>
                            )
                        </li>
                        <li>
                            Size 40 is B (
                            <span className="blog-matching-measurement">
                                36 7/32"
                            </span>
                            ), W (
                            <span className="blog-matching-measurement">
                                28 35/64"
                            </span>
                            ), H (39 3/8")
                        </li>
                    </ul>
                    Neither size fit perfectly, (my hips are often in
                    a smaller size range than my waist), but I knew
                    there was a good amount of ease, so I use Size 40
                    in the top &amp; graded to a Size 38 at the crotch
                    &amp; below.
                </Col>
            </Row>
            <Row>
                <Col lg={10}>
                    <br />
                    This pattern comes in two range sets, Range 1:
                    32-46 drafted for B cup and Range 2: 46-58 drafted
                    for D cup. Size 58 is B (54 23/32") W(48 13/16")
                    H(59 29/64").
                </Col>
            </Row>
        </>
    );
};
