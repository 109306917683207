import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './section11.css';
import { PostTitle } from '../PostTitle';

export const ImageListFor4Image = ({ imageList, title }) => {
    return (
        <>
            <PostTitle title={title} />
            <Row>
                {imageList.map((item, index) => (
                    <Col key={index} md={3}>
                        <img
                            className="w-100 post-img"
                            src={item}
                            alt="TODO"
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
