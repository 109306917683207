import React from 'react';

import valleyThumbnail from '../../assets/img/valley-thumbnail.jpg';
import leatherBustier from '../../assets/img/leather-bustier.png';
import patternhack from '../../assets/img/patternhack.png';

import './homeMain.css';
import { Link } from 'react-router-dom';

const mainArr = [
    {
        img: valleyThumbnail,
        title: 'Fab Peppermint Valley Jumpsuit',
        link: 'valley',
    },
    {
        img: leatherBustier,
        title: 'Leather Learnings: Rose Cafe Bustier',
        link: 'leatherbustier',
    },
    {
        img: patternhack,
        title: 'Heather Blazer Pattern Hack: Cropped!',
        link: 'croppedblazer',
    },
];

export const HomeMain = () => {
    return (
        <div className="main-container">
            {mainArr.map(({ img, title, link }, index) => (
                <div className="tile-container" key={index}>
                    <Link to={link}>
                        <img src={img} alt={title} />
                        <div className="grid-tile-title">{title}</div>
                    </Link>
                </div>
            ))}
        </div>
    );
};
