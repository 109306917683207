import React from 'react';
import {
    PostEnd,
    PostTitleContainer,
    ValleyMain,
} from '../../components';

const valleyBlog = {
    title: 'Fab Peppermint Valley Jumpsuit',
    date: 'January 29, 2023',
    slogan: '',
    linkTitle: 'Peppermint Valley Jumpsuit',
    link: 'https://peppermintmag.com/sewing-school/valley-jumpsuit/',
};

export const Valley = () => {
    return (
        <>
            <PostTitleContainer data={valleyBlog} />
            <ValleyMain />
            <PostEnd />
        </>
    );
};
