import React from 'react';
import { Col, Row } from 'react-bootstrap';
import star from '../../assets/img/star.gif';
import './research.css';
import { PostTitle } from '../PostTitle';

export const Research = ({
    title,
    textBefore,
    listItems,
    subDescr = '',
}) => {
    return (
        <>
            <PostTitle title={title} />
            <Row className="m-2">
                <Col>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: textBefore,
                        }}></div>
                </Col>
            </Row>
            {subDescr && <Row className=" m-2">{subDescr}</Row>}

            <Row className="m-2">
                <Col>
                    <ul>
                        {listItems.map((item, i) => (
                            <div key={i}>
                                <li className="blog-post-note">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}></div>
                                </li>
                                {item.content && (
                                    <ul>
                                        {item.content.map(
                                            (contentItem, j) => (
                                                <li key={j}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: contentItem,
                                                        }}></div>
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                )}
                            </div>
                        ))}
                    </ul>
                </Col>
            </Row>
            <Row className="m-2 thank-you">
                <Col sm={1}>
                    <img
                        className="w-100 post-img"
                        src={star}
                        alt="TODO"
                    />
                </Col>
                <Col className="justify-content-center text-align-center">
                    I am so grateful for the opportunity to learn from
                    other makers -- thank you for sharing your notes
                    &amp; experience!!
                </Col>
                <Col sm={1}>
                    <img
                        className="w-100 post-img"
                        src={star}
                        alt="TODO"
                    />
                </Col>
            </Row>
        </>
    );
};
