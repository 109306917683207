import './sendForm.css';

export const SendForm = () => {
    return (
        <div className="form">
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSek2Hyycc8pw7r-dZgL7wHzP2DrW64tAlOmuhiOVSwYwG63Xw/viewform?embedded=true"
                width="710"
                height="700"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0">
                Loading…
            </iframe>
        </div>
    );
};
