import React from 'react';
import { Col, Row } from 'react-bootstrap';
import inspiration1 from '../../../assets/img/inspiration1.png';
import inspiration2 from '../../../assets/img/inspiration2.png';
import inspiration3 from '../../../assets/img/inspiration3.png';
import './section2.css';
import { PostTitle } from '../../PostTitle';

const contentArr = [
    {
        title: "Aaliyah's Valley jumpsuit",
        img: inspiration1,
        link: 'https://www.instagram.com/p/CcyIXw4p6lV/',
    },
    {
        title: "Rumana's Valley jumpsuit",
        img: inspiration2,
        link: 'https://www.instagram.com/p/CPI-jhAMUE9/',
    },
    {
        title: "Christina's Valley jumpsuit",
        img: inspiration3,
        link: 'https://www.instagram.com/p/CUwZ2IYljD3/',
    },
];

export const Section2 = () => {
    return (
        <>
            <PostTitle title="Inspiration" />
            <Row className="m-2">
                <Col className="col">
                    These are the jumpsuit makes that got the wheels
                    turning for how I could use the Valley jumpsuit to
                    make something FAB!
                </Col>
            </Row>
            <Row>
                {contentArr.map(({ title, img, link }, index) => (
                    <Col key={index}>
                        <img
                            className="w-100"
                            src={img}
                            alt={title}
                        />
                        <span className="post-img-caption">
                            <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer">
                                {title}
                            </a>
                        </span>
                    </Col>
                ))}
            </Row>
            <Row>
                <Col>
                    I loved Rumana's version's longer sleeves &amp;
                    cuff. I thought the denim patchwork on Aaliyah's
                    make was SO COOL. And I liked Christina's
                    crew-neck alteration/the note on needing to add
                    fabric to have enough arm movement -- also that
                    patternmatching is incredible. We all love arm
                    mobility!
                </Col>
            </Row>
        </>
    );
};
