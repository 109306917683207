import React from 'react';
import { Col, Row } from 'react-bootstrap';

export const ImageList = ({ images, title, titleLink = {} }) => {
    return (
        <>
            <Row className="blog-section-title">
                {title}{' '}
                {titleLink && (
                    <a
                        href={titleLink.link}
                        target="_blank"
                        rel="noopener noreferrer">
                        {titleLink.title}{' '}
                    </a>
                )}
            </Row>
            <Row>
                {images.map(({ img, title, link }, index) => (
                    <Col md={4} key={index}>
                        <img
                            className="w-100 post-img"
                            src={img}
                            alt={title}
                        />
                        <span className="post-img-caption">
                            <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer">
                                {title}
                            </a>
                        </span>
                    </Col>
                ))}
            </Row>
        </>
    );
};
